import React from "react";
import { CloseIcon } from "../../assets/Icons";
import "./styles.scss";


const PopupTopbar = ({ onClick, text, absolute }) => {
    return <div className={`popup__top__bar ${absolute ? "pos__absolute" : ""}`}>
        <div>
            <button onClick={onClick}>
                <CloseIcon size={18} />
            </button>
        </div>
        <div className="title__text">
            {text}
        </div>
    </div>
}

export default PopupTopbar;