import React from 'react';
import './styles.scss';
import Spinner from './Spinner';

const Loader = () => {
    return (
        <div className='loading__div'>
            <Spinner />
        </div>
    )
}

export default Loader;