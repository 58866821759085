import React, { useState } from "react";
import slackLogo from "../../assets/svg/slack.svg";
import { slackLogin } from "../../api/apis/auth";
import "./styles.scss";
import { useSelector } from "react-redux";
import { heroCardChat } from "../../constant/Common";
import Spinner from "../../components/Loader/Spinner";

export default function Hero() {
  const { loginStatus } = useSelector((state) => state);

  const [btnLoading, setBtnLoading] = useState(false);
  const loginToSlack = async () => {
    setBtnLoading(true);
    slackLogin(setBtnLoading);
  }

  return (
    <div className="hero_container">
      <div className="left_container">
        <div>
          <h2 className="hero_heading">
            Chat with your data like a{" "}
            <span className="heading_highlight">human</span>
          </h2>
          <p className="hero_sub_content">
            Engage with your business data conversationally, right within Slack.
          </p>
          {!loginStatus ? (
            <div className="cta_btn_container">
              <button disabled={btnLoading} onClick={loginToSlack} className="cta_container">
                {btnLoading ? <Spinner /> : <div className="cta_container">
                  <img src={slackLogo} className="cta_img" alt="" />
                  <p className="cta_text">Add to Slack</p>
                </div>}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="line"></div>
      </div>
      <div className="right_container">
        <div className="list_container">
          <h3 className="title">#aha-questions</h3>
          <div className="list_line"></div>
          {heroCardChat.map((item, index) => {
            return (
              <div key={index} className="list_content">
                <div
                  className={item.img.is_logo ? "list_img_logo_background" : ""}
                >
                  <img
                    src={item.img.path}
                    className={
                      item.img.is_logo ? "list_img_logo" : "list_img_user"
                    }
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="user_name">{item.title}</h4>
                  <p class="user_caption">{item.user_caption}</p>
                </div>
              </div>
            );
          })}
          <div className="list_line"></div>
        </div>
      </div>
    </div>
  );
}
