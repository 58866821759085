import React, { useEffect, useState } from "react";
import AnalyticsPopup from "../../components/Popup/Analytics";
import MainLayout from "../../layouts/MainDashboardComponent";
import "./styles.scss";

import { useLocation, useNavigate } from "react-router-dom";
export default function AddProperties() {
  const [noAccFound, setNoAccFound] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log("Acc Id", params.get("accId"))
    if (params.get("accId") === "false") {
      setNoAccFound(true)
    }
  }, [])


  return (
    <MainLayout>
      <div className="content_container_dashboard">
        <AnalyticsPopup
          onClose={() => {
            navigate(location?.state?.dashboard ? -1 : "/dashboard");
          }}
          dashboard
          noAccFound={noAccFound}
          setNoAccFound={setNoAccFound}
        />
      </div>
    </MainLayout>);
}
