//import components
import { Home, TermAndCondition, PrivacyPolicy, AddProperties, ConnectSlack, ChannelCreate } from "../views";
import Connect from "../views/Connect";
// import ConnectSlackChannel from "../views/ConnectSlackChannel";
import Dashboard from "../views/Dashboard";
import SelectChannel from "../views/SelectChannel";
import SuperAdmin from "../views/SuperAdmin";

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/term-and-condition",
    element: <TermAndCondition />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/connect",
    element: <Connect />,
    privateRoute: true
  },
  {
    path: "/connect-slack",
    element: <ConnectSlack />,
    privateRoute: true
  },
  // {
  //   path: "/connect-slack-channel",
  //   element: <ConnectSlackChannel />,
  //   privateRoute: true
  // },
  {
    path: "/dashboard",
    element: <Dashboard />,
    privateRoute: true
  },
  {
    path: "/super-admin",
    element: <SuperAdmin />,
    privateRoute: true
  },
  {
    path: "/add-properties",
    element: <AddProperties />,
    privateRoute: true
  },
  {
    path: "/select-channel/:id",
    element: <SelectChannel />,
    privateRoute: true
  },
  {
    path: "/create-channel",
    element: <ChannelCreate />,
    privateRoute: true
  },
];
export const navigateWithParams = (navigate, path, params) => {
  const queryString = new URLSearchParams(params).toString();
  navigate(`${path}?${queryString}`);
};
