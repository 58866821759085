import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const API_VERSION = "/api/v1/" 
// const API_VERSION = "" 
export const API_URL = BASE_URL+API_VERSION;


export const SERVER_AXIOS = axios.create({
  baseURL: API_URL,
});

