import React, { useEffect, useState, useMemo } from "react";
import slack from "../../../assets/svg/slack.svg";
import CreateChannel from "../ChannelCreate";
import Continue from "../../Button/Continue";
import Loader from "../../Loader";
import Spinner from "../../Loader/Spinner";
import BulletBtn from "../../Button/BulletBtn";
import PopupTopbar from "../../PopupTopbar";
import { getSlackChannels } from "../../../api/apis/slack";
import { fetchProperties, getPropertyById, updatePropertyById } from "../../../api/apis/analytics";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";

export default function SlackPopup(props) {
  const {
    channels,
    setChannels,
    setOldChannels,
    onClose,
  } = props;

  const params = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [bntLoading, setBtnLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [currentChanel, setCurrentChannel] = useState();
  const [channelsData, setChannelsData] = useState([]);
  const [previousSelected, setPreviousSelected] = useState();
  const [createChannelPopup, setCreateChannelPopup] = useState(false);
  const [properties, setProperties] = useState([])


  const getChannels = async () => {
    setIsLoading(true);
    const response = await getSlackChannels();
    if (response?.data) {
      const { channels } = response?.data;
      setChannels(channels ?? []);
      setOldChannels(channels ?? []);
      setIsLoading(false);
    }
  };

  const propertyData = async () => {
    const propertyData = await getPropertyById(params?.id);
    if(propertyData?.status){
      setPreviousSelected(propertyData?.data?.channelId);
      setCurrent(propertyData?.data?.channelId);
      setCurrentChannel(propertyData?.data?.channelId ? { id: propertyData?.data?.channelId, name: propertyData?.data?.channelName } : null)
      setProperty(propertyData?.data)
    }else{
      navigate("/dashboard",{replace:true})
    }
  }

  const filteredChannels = (channels) => {
    const propertiesChannels = properties?.map((v) => v?.channelId) ?? [];
    const filtered = channels.filter((ch) => {
      return (
        !propertiesChannels?.includes(ch?.id) || ch?.id === previousSelected
      );
    });
    return filtered;
  };

  const fetchAnalyticsProperties = async () => {
    const { data } = await fetchProperties();
    setProperties(data?.selectedProperties ?? []);
  }

  useEffect(() => {
    getChannels();
    propertyData();
    fetchAnalyticsProperties()
  }, []);

  useMemo(() => {
    setChannelsData(filteredChannels(channels));
  }, [channels, properties]);

  const updateChannel = async () => {
    setBtnLoading(true);
    const payload = {
      channelId: currentChanel?.id ?? "",
      channelName: currentChanel?.name ?? "",
    };
    await updatePropertyById(payload, property?._id);
    setBtnLoading(false);
    onClose();
  };

  const channelChanged = (channel) => {
    setCurrent((id) => (channel.id === id ? null : channel.id));
    setCurrentChannel((ch) => (ch?.id === channel?.id ? {} : channel));
  };

  const handelSearch = (event) => {
    const filteredChannelsData = filteredChannels(channels)?.filter((item) =>
      item?.name?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setChannelsData(filteredChannelsData);
  };

  const handleCreateChannelPopup = () => navigate("/create-channel",{state:{propertyId:property?._id}});
  const createChannelClose = () => setCreateChannelPopup(false);

  return (
    <>
      {createChannelPopup ? <CreateChannel getChannels={getChannels} channelsData={channelsData} onClose={createChannelClose} /> : <div className="slack__popup__main">
        <PopupTopbar
          onClick={onClose}
          text={`Slack Channel |  ${property?.name}`}
        />
        <div className="popup__section">
          {isLoading ? <Loader /> : ""}
          <div className="popup__container">
            <div className="__popup">
              <div className="heading_logo_container">
                <img src={slack} className="analytics_logo" alt="" />
                <h3 className="heading">
                  Slack a channel to connect {property?.name}
                </h3>
              </div>
              <div className="line"></div>

              <div className="search_container">
                <input
                  placeholder="Search"
                  className="search_input"
                  onChange={handelSearch}
                />
              </div>
              <div>
                <p className="notes_para">
                  Note: we can only fetch your public slack channels. If you want
                  to create a new slack channel just for this data{" "}
                  <span className="high_lighted cursor__pointer" onClick={handleCreateChannelPopup}>
                    click here
                  </span>
                </p>
                <button
                  className="add_slack_button"
                  onClick={handleCreateChannelPopup}
                >
                  Add Slack Channel
                </button>
              </div>
              <div className="py-2 scroll_container">
                {channelsData?.map((v, i) => (
                  <div className="channel__list__item">
                    <div className="d-flex align-items-center">
                      <BulletBtn
                        onClick={() => channelChanged(v)}
                        active={v?.id === current}
                      />
                    </div>
                    <div className="channel__name">{v?.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="continue__btn__container">
              <Continue
                onClick={updateChannel}
                text={bntLoading ? <Spinner /> : "Save"}
                disabled={bntLoading}
              />
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
