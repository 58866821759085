import React from "react";
import NavbarComponent from "../../components/Navbar";
import "./styles.scss";
import AdminDashboardPopup from "../../components/Popup/UserListPopup";
export default function SuperAdmin() {


  return (
    <div className="main_container">
      <NavbarComponent pageName={"dashboard"} />
      <div className="content_container_dashboard">
        <AdminDashboardPopup />
      </div>
    </div>
  );
}
