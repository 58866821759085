import React, { useEffect, useState } from "react";
import "./styles.scss";
import GAnalytics from "../../../assets/svg/GAnalytics.svg";
import Continue from "../../Button/Continue";
import PropertiesSelect from "../../Select/Properties";
import {
  connectAnalytics,
  fetchAccountsByUser,
  fetchProperties,
  updateProperties,
} from "../../../api/apis/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import Spinner from "../../Loader/Spinner";
import { navigateAccToData } from "../../../functions/common";
import PopupTopbar from "../../PopupTopbar";
import NoAccountPopup from "../NoAccount";
import { toast } from "react-toastify";

export default function AnalyticsPopup({
  onClose,
  connected,
  dashboard,
  noAccFound,
  setNoAccFound,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [analyticsAccounts, setAnalyticsAccounts] = useState([]);
  const [analyticsProperties, setAnalyticsProperties] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deletedProperties, setDeletedProperties] = useState([]);
  const [oldAnalyticsProperties, setOldAnalyticsProperties] = useState([]);


  useEffect(() => {
    fetchAnalyticsAccounts();
  }, []);

  const fetchAnalyticsAccounts = async () => {
    setLoading(true);
    const response = await fetchAccountsByUser();
    if (response.status) {
      setAnalyticsAccounts(response.data);
    }
    const { data } = await fetchProperties();
    setAnalyticsProperties(data.properties);
    setOldAnalyticsProperties(data.selectedProperties);
    setSelected(data.selectedProperties);
    setLoading(false);
  };

  const updateMyProperties = async () => {
    if(selected?.length < 1){
      return toast.error("Please select atleast one property")
    }
    setBtnLoading(true);
    const deleted = [];
    const selectedProperties = [];
    deletedProperties.forEach((item) => {
      if (!deleted.some((v) => v.propertyId === item.propertyId) &&
        oldAnalyticsProperties.some((v) => v.propertyId === item.propertyId) &&
        !selected.some((v) => v.propertyId === item.propertyId)
      ) {
        deleted.push(item)
      }
    })
    selected.forEach((item) => {
      if (!selectedProperties.some((v) => v.propertyId === item.propertyId) &&
        !oldAnalyticsProperties.some((v) => v.propertyId === item.propertyId)
      ) {
        selectedProperties.push(item)
      }
    })
    const data = {
      properties: selectedProperties,
      deletedProperties: deleted
    };
    const response = await updateProperties(data);
    if (response.status) {
      dashboard ? onClose() : navigateAccToData(navigate, true);
    }
    setBtnLoading(false);
  };

  const handleSelection = (property) => {
    if (selected.some((item) => item.propertyId === property.propertyId)) {
      handleDelete(property)
      setSelected(selected.filter((item) => item.propertyId !== property.propertyId));
    } else {
      setSelected([...selected, property]);
    }
  };

  const handleDelete = (property) => {
    setDeletedProperties((prev) => {
      return [...prev, property];
    });
  };

  const onCloseNoAccPopup = () => {
    setNoAccFound(false);
    navigate(location.pathname, { replace: true });
  };

  return (
    <div>
      <PopupTopbar
        onClick={onClose}
        text={"Add Properties & Apps"}
        absolute={true}
      />
      {noAccFound ? (
        <NoAccountPopup onClose={onCloseNoAccPopup} />
      ) : (
        <div>
          {" "}
          <div className="analytic_popup_container">
            {loading ? <Loader /> : ""}
            <div>
              <div className="heading_container">
                <div className="heading_logo_container justify-content-between">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={GAnalytics} className="analytics_logo" alt="" />
                    <h3 className="heading">
                      Connect Google Analytics 4 Account
                    </h3>
                  </div>
                </div>
                <div className="line"></div>
              </div>
              <div>
                <div className="account_head_container">
                  <span className="head_name">Account</span>
                  <span
                    onClick={() => connectAnalytics(true)}
                    className="add_account cursor__pointer"
                  >
                    Add Another Account
                  </span>
                </div>
                <div className="account_email_container">
                  {analyticsAccounts?.map((item, index) => {
                    return <p className="account_email">{item.email}</p>;
                  })}
                </div>

                <div className="apps_select_container">
                  <p className="apps_select_heading">
                    Select Properties & Apps
                  </p>
                  <PropertiesSelect
                    selected={selected}
                    setSelected={setSelected}
                    analyticsProperties={analyticsProperties}
                    handleSelection={handleSelection}
                  />
                  <div>
                    {selected?.map((property, propIndex) => (
                      <div className="properties_container" key={propIndex}>
                        <div className="properties_name_container">
                          <p className="properties_name">{property?.name}</p>
                          <p className="properties_id">{property?.propertyId}</p>
                        </div>
                        <div className="properties_remove_container">
                          <span
                            onClick={() => {
                              handleSelection(property);
                            }}
                            className="remove_btn cursor__pointer"
                          >
                            Remove
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="continue_close_btn_container">
            <div>
              <Continue
                onClick={() => updateMyProperties()}
                text={
                  connected && btnLoading ? <Spinner /> : connected && "Save"
                }
                disabled={btnLoading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
