import { apiError } from "../../../functions/common";
import { getToken } from "../../../functions/localstorage";
const { GET_METHOD, POST_METHOD } = require("../../axiosMethods");

const getHeaders = (token) => ({
  Authorization: token ?? getToken(),
});

//get slack channels
export const getSlackChannels = async () => {
  try {
    const { data } = await GET_METHOD(`/slack/channels`, {
      headers: getHeaders(),
    });
    return data;
  } catch (error) {
    return apiError(error);
  }
};

//create slack channel
export const createSlackChannel = async (payload) => {
  try {
    const { data } = await POST_METHOD(`/slack/channel/create`, payload, {
      headers: getHeaders(),
    });
    return data;
  } catch (error) {
    return apiError(error);
  }
};

//update slack channels
export const updateSlackChannels = async (payload) => {
  try {
    const { data } = await POST_METHOD(`/slack/channels`, payload, {
      headers: getHeaders(),
    });
    return data;
  } catch (error) {
    return apiError(error);
  }
};

//slack channel webhook
export const getSlackChannelWebhook = async (redirect) => {
  try {
    const { data } = await GET_METHOD(
      `/slack/channels/webhooks?redirect=${redirect ?? ""}`,
      { headers: getHeaders() }
    );
    window.location.href = data;
  } catch (error) {
    return apiError(error);
  }
};