import React from "react";
import "./styles.scss";
import { providers } from "../../constant/Common"

export default function Connect() {

  return (
    <div className="connect_container">
      <div className="content_bg">
        <div className="title_container">
          <h3 className="title text-center">Here's where we can connect</h3>
          <p className="sub_title text-center">
            We fine-tune our AI model exclusively on your data, enabling you to
            discover deeper insights (Aha-moments) through chat.
          </p>
        </div>
        <div className="provider_container">
          {providers?.map((item, index) => {
            return (
              <div
                key={index}
                className={`provider_card`}
              >
                <img src={item?.logo} className="provider_image" alt="" />
                <div className="provider_detail">
                  <div className="title_btn_container">
                    <h4 className="provider_name">{item?.name}</h4>
                    {!item?.connected && (
                      <button className="coming_btn">Coming Soon</button>
                    )}
                  </div>
                  <p className="provider_content">{item?.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
