import GAnalytics from "../assets/images/GAnalytics.png";
import adwords from "../assets/svg/adwords.svg";
import stripe from "../assets/images/stripe.png";
import shopify from "../assets/svg/shopify.svg";
import mailchimp from "../assets/images/mailchimp.png";
import facebook from "../assets/images/facebook.png";
import userSvg from "../assets/svg/user.svg";
import chatLogo from "../assets/svg/chat-logo.svg";

export const providers = [
  {
    name: "Google Analytics 4",
    content:
      "Google Analytics can be complex. Just ask it questions naturally and receive clear answers.",
    logo: GAnalytics,
    connected: true,
  },
  {
    name: "Google Adwords",
    content:
      "Determine your spending and quickly identify your top-performing and underperforming ads.",
    logo: adwords,
    connected: false,
  },
  {
    name: "Stripe",
    content:
      "Gain insights into your revenue and customers directly, in Slack without having to login to Stripe.",
    logo: stripe,
    connected: false,
  },
  {
    name: "Shopify",
    content:
      "Grasp your revenue patterns, identify top-performing and lagging products, and gain insights into your customer base.",
    logo: shopify,
    connected: false,
  },
  {
    name: "Mailchimp",
    content:
      "Discover who's subscribing, who's leaving, and which campaigns are most popular—all through Slack",
    logo: mailchimp,
    connected: false,
  },
  {
    name: "Facebook Ads",
    content:
      "Determine your spending and quickly identify your top-performing and underperforming ads.",
    logo: facebook,
    connected: false,
  },
];

export const heroCardChat = [
  {
    title: "Jordan Wang",
    user_caption: (
      <>
        <span className="user_hashtag">@aha-moment</span> How many visitors did I get
        yesterday?
      </>
    ),
    img: {
      path: userSvg,
      is_logo: false,
    },
  },
  {
    title: "aha moment",
    user_caption:
      "You had 3,292 total visitors to your site on Monday, October 2, 2023.",
    img: {
      path: chatLogo,
      is_logo: true,
    },
  },
  {
    title: "Jordan Wang",
    user_caption: (
      <>
        <span className="user_hashtag">@aha-moment</span> How many of them are desktop
        vs mobile?
      </>
    ),
    img: {
      path: userSvg,
      is_logo: false,
    },
  },
  {
    title: "aha moment",
    user_caption: "You had 2,633 on mobile and 658 on desktop.",
    img: {
      path: chatLogo,
      is_logo: true,
    },
  },
  {
    title: "Jordan Wang",
    user_caption: "Amazing thanks! 👍",
    img: {
      path: userSvg,
      is_logo: false,
    },
  },
];
