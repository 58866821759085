import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginStatus: false,
  userData: {},
  isLoading:false
};

const counterSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateLoginStatus: (state, { payload }) => {
      state.loginStatus = payload;
    },
    updateUserData: (state, { payload }) => {
      state.userData = payload;
    },
    updateLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const {
  updateLoginStatus,
  updateUserData,
  updateLoading
} = counterSlice.actions;
export default counterSlice.reducer;
