import { Provider } from "react-redux";
import "./App.scss";
import { Router } from "./config/router";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Router />
    </Provider>
  );
}

export default App;
