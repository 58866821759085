import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

export default function Properties({ analyticsProperties, selected, handleSelection }) {

  const [toggle, setToggle] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const selectRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setToggle(false);
    }
  };
  return (
    <div className="select_container" ref={selectRef}>
      <div className="select_input_container">
        <input
          className="select_input_placeholder"
          placeholder="Search"
          onChange={(event) => {
            setSearchVal(event.target.value);
          }}
          onFocus={() => setToggle(true)}
        />
        <span className="select_icon" onClick={() => setToggle(!toggle)}></span>
      </div>
      {toggle && (
        <div className="dropdown__list">
          {searchVal
            ? analyticsProperties
                .filter((item) =>
                  item.name.toLowerCase().includes(searchVal.toLowerCase())
                )
                .map((property, propIndex) => {
                  return (
                    <div className="select_items_container" key={propIndex}>
                      <div
                        className={`${
                          selected?.find((item) => item.propertyId === property.propertyId)
                            ? "selected_check_box"
                            : "select_check_box"
                        }`}
                        onClick={() => handleSelection(property)}
                      ></div>
                      <div className="select_meta_data_container">
                        <p className="select_item_name">{property.name}</p>
                        <p className="select_item_id">{property.propertyId}</p>
                      </div>
                    </div>
                  );
                })
            : analyticsProperties.map((property, propIndex) => {
                return (
                  <div className="select_items_container" key={propIndex}>
                    <div
                      className={`${
                        selected?.find((item) => item.propertyId === property.propertyId)
                          ? "selected_check_box"
                          : "select_check_box"
                      }`}
                      onClick={() => handleSelection(property)}
                    ></div>
                    <div className="select_meta_data_container">
                      <p className="select_item_name">{property.name}</p>
                      <p className="select_item_id">{property.propertyId}</p>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
}
