import React from "react";
import "./styles.scss";
import Spinner from "../../Loader/Spinner";
export default function Continue({ onClick, text, disabled, btnLoading }) {
  return (
    <button disabled={disabled ?? false} className="continue_btn" onClick={() => onClick()}>
      {btnLoading ? <Spinner /> : text ? text : "Continue"}
    </button>
  );
}
