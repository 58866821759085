import React, { useState } from "react";
import Spinner from "../../Loader/Spinner";
import PopupTopbar from "../../PopupTopbar";
import Continue from "../../Button/Continue";
import { createSlackChannel } from "../../../api/apis/slack";
import "./styles.scss";
import slack from "../../../assets/svg/slack.svg";

export default function CreateChannel(props) {
  const {
    onClose,
  } = props;

  const [btnLoading, setBtnLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState("");

  const handleChangeName = (e) => {
    setName(e.target.value)
    setError("")
  }

  const handleCreateChannel = async () => {
    if (!name || name === "") {
      setError("Please enter channel name");
      return;
    }
    // if (channelsData?.find(v => v?.name === name)) {
    //   setError("Channel name already exists");
    //   return;
    // }
    setBtnLoading(true);
    const response = await createSlackChannel({ name });
    if (response.status) {
      setBtnLoading(false)
      onClose()
    } else {
      setBtnLoading(false)
      setError(response.message === "name_taken" ? "Channel name already exists" : "Something went wrong")
    }
  };

  return (
    <div className="slack__popup__main">
      <PopupTopbar
        onClick={onClose}
        text={`Create a New Slack Channel`}
      />
      <div className="popup__section">
        <div className="popup__container">
          <div className="__popup">
            <div className="heading_logo_container">
              <img src={slack} className="analytics_logo" alt="" />
              <h3 className="heading">
                Create a New Slack Channel
              </h3>
            </div>
            <div className="line"></div>

            <div className="mt-4">
              <div className="label">
                Name:
              </div>
              <input
                placeholder="e.g. plan-budget"
                className="search_input"
                onChange={handleChangeName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleCreateChannel()
                }}
              />
              {error && error !== "" && <div className="error">{error}</div>}
              <div className="notes_para">
                Channels are where conversations happen around a topic. Use a name that is easy to find and understand.
              </div>
            </div>
            <div className="py-2">

            </div>
          </div>
          <div className="continue__btn__container">
            <Continue
              onClick={handleCreateChannel}
              text={btnLoading ? <Spinner /> : "Save"}
              disabled={btnLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
