//add token
export const addToken = (token) => {
    localStorage.setItem("aha__token", token);
};

//get token
export const getToken = () => localStorage.getItem("aha__token");

//remove token
export const removeToken = () => localStorage.removeItem("aha__token");

//add token
export const addCurrentToken = (token) => {
    localStorage.setItem("aha__i__token", token);
};

//get token
export const getCurrentToken = () => localStorage.getItem("aha__i__token");

//remove token
export const removeCurrentToken = () => localStorage.removeItem("aha__i__token");