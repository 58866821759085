import { fetchDataSourcesCounts } from "../api/apis/user"
import moment from "moment"

export const apiError = (error) => {
    return {
        error,
        status: false,
    }
}

export const navigateAccToData = async (navigate, skipAnalytics) => {
    try {
        const { data: { slackIntegration, analyticsIntegration } } = await fetchDataSourcesCounts();
        if (!skipAnalytics && analyticsIntegration < 1) {
            navigate("/connect")
        } else if (slackIntegration < 1) {
            navigate("/connect-slack")
        } else {
            navigate("/dashboard")
        }
    }
    catch (e) {
        console.log("Some error in API")
    }
}

export const getNumbFormattedDate = (date) => {
    return moment(date).calendar();
}