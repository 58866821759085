import React, { useEffect, useState } from "react";
import Loader from "../../Loader";


import "./styles.scss";
import { fetchAllUsersList, fetchUserTokenById } from "../../../api/apis/user";
import { getNumbFormattedDate } from "../../../functions/common";
import { addCurrentToken, addToken, getToken } from "../../../functions/localstorage";

export default function AdminDashboardPopup() {

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    setIsLoading(true);
    const users = await fetchAllUsersList();
    setUsers(users?.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, [])

  const impersonateUser = async (id) => {
    const token = await fetchUserTokenById(id);
    const myToken = getToken();
    addCurrentToken(myToken);
    addToken(token?.token);
    window.location.replace('/dashboard')
  }

  return (
    <div className="admin_popup_container">
      {isLoading ?
        <Loader />
        : ""}
      <div className="heading_container">
        <div className="heading">Admin Dashboard</div>
        <div className="sub_title">
          {users?.length} User{users?.length > 1 ? "s" : ""}
        </div>
        <div className="line"></div>
      </div>
      <div className="user__list__container">
        {users?.map((item, index) => {
          return (
            <div className="d-flex justify-content-between align-items-center" key={index}>
              <div className="user_container">
                <div className="user_name">{item?.fullName}</div>
                <div className="user__join__date">Sign up on: {getNumbFormattedDate(item?.createdAt)}</div>
              </div>
              <div>
                <button onClick={() => impersonateUser(item?._id)} className="imporsonate__btn">Impersonate</button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}
