import React from 'react'
import NavbarComponent from '../../components/Navbar'
import Contact from '../../layouts/Contact'
import './styles.scss'
import { mail } from '../../utils/constants'

export default function PrivacyPolicy() {
    return (
        <div>
            <NavbarComponent pageName="home" />
            <div className='privacy_policy_container'>
                <div>
                    <h2 className='main_heading'>Privacy Policy</h2>
                </div>
                <div className='content_container'>
                    <h3 className='content_heading'>1. Introduction</h3>
                    <p className='content'>Welcome to <a href='https://ahamoment.co/'>https://ahamoment.co/</a>  ("Website," "Service," or "Platform"). This Privacy Policy ("Policy") explains how we collect, use, and protect your personal information. By accessing or using our website, you consent to the practices described in this Policy.</p>

                    <h3 className='content_heading'>2. Information We Collect</h3>

                    <p className='content'>2.1 Personal Information <br />We may collect personal information, such as your name, email address, postal address, phone number, and other information when you voluntarily provide it to us through forms on our website.</p>

                    <p className='content'>2.2 Usage Information <br />We may automatically collect information about your interaction with our website, including your IP address, browser type, pages visited, and other usage data. We may use cookies and similar technologies to collect this information.</p>

                    <h3 className='content_heading'>3. How We Use Your Information</h3>

                    <p className='content'>3.1 Personalization <br />We may use your information to personalize your experience on our website and provide you with relevant content and recommendations.</p>

                    <p className='content'>3.2 Communication <br />We may use your contact information to communicate with you about our products, services, promotions, and updates. You may opt-out of receiving such communications at any time.</p>
                    
                    <p className='content'>3.3 Analytics <br />We may use your information for analytics purposes to improve our website, products, and services.</p>

                    <h3 className='content_heading'>4. Data Security</h3>

                    <p className='content'>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no data transmission over the internet or method of electronic storage is entirely secure, and we cannot guarantee the absolute security of your data.</p>
                    
                    <p className='content'>We value your privacy and are committed to ensuring the confidentiality of your personal information. We do not share, transfer, or disclose your personal data, including Google user data, to any unauthorized third parties, except as required by law or with your explicit consent.</p>
                    
                    <p className='content'>The data you provide is processed by OpenAI to fulfill your requests and is formatted into Slack messages, which are then sent to the relevant Slack API to deliver responses to your channel. Your data is handled securely throughout this process, and we do not share it with any other third parties beyond this scope. By using the service you agree to this data sharing.</p>
                    
                    <p className='content'>Your data is used solely for the purposes outlined in this Privacy Policy, and we ensure it is handled with the utmost care and security when working with trusted third-party services like Slack, and OpenAI.</p>
                    
                    <p className='content'><h3 className='content_heading'>Accessing Google Analytics Data</h3>We access the Google Analytics Data API to retrieve the necessary data in response to your queries. This data is used solely for the purpose of answering your specific requests and is not stored after processing. Once your query has been fulfilled, the data is immediately disposed of.<br />Our use and transfer of any information received from Google APIs strictly adhere to the <a href='https://developers.google.com/terms/api-services-user-data-policy'>Google API Services User Data Policy</a>, including the <a href='https://developers.google.com/terms/api-services-user-data-policy?#additional_requirements_for_specific_api_scopes'>Limited Use requirements</a>.</p>

                    <p className='content'>Whenever you inquire about your metrics, we access your data only at that moment and do not store it in our system. However, for historical reference, we save images of the graphs for 1 year. These images will be automatically deleted after the one-year period.</p>

                    <h3 className='content_heading'>5. Third-Party Links</h3>

                    <p className='content'>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of these websites.</p>

                    <h3 className='content_heading'>6. Your Rights</h3>

                    <p className='content'>You may have certain rights regarding your personal information, including the right to access, correct, or delete your data. To exercise these rights or for any questions about your data, please contact us at <a href={`mailto:${mail}`}>{mail}</a>.</p>

                    <h3 className='content_heading'>7. Changes to this Privacy Policy</h3>

                    <p className='content'>We reserve the right to modify or replace this Privacy Policy at any time. Please check this Policy periodically for changes. By continuing to use our website after any changes to this Policy, you agree to be bound by the revised Policy.</p>

                    <h3 className='content_heading'>8. Contact Us</h3>

                    <p className='content'>If you have any questions about this Privacy Policy, please contact us at <a href={`mailto:${mail}`}>{mail}</a>.</p>

                    <p className='content'>Last Updated: 9/11/2024</p>
                </div>
            </div>
            <section id="contact">
                <Contact />
            </section>
        </div>
    )
}
