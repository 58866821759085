import { apiError } from "../../../functions/common";
import { getToken } from "../../../functions/localstorage";
import { GET_METHOD, POST_METHOD, PUT_METHOD } from "../../axiosMethods";

const getHeaders = (token) => ({
    Authorization: token ?? getToken(),
});

//get my data
export const connectAnalytics = async (another) => {
    try {
        const { data } = await GET_METHOD(`/analytics`, { headers: getHeaders(), params: { another } });
        if (data.status) {
            window.location.href = data.redirectUrl
        }
    } catch (error) {
        return false;
    }
};

//get my data
export const fetchAnalytics = async (id) => {
    try {
        const { data } = await GET_METHOD(`/analytics/account/${id}`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//get my data
export const fetchAccountsByUser = async (id) => {
    try {
        const { data } = await GET_METHOD(`/analytics/accounts`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//get my properties
export const fetchProperties = async (id) => {
    try {
        const { data } = await GET_METHOD(`/analytics/properties`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//update selected properties
export const updateProperties = async (body) => {
    try {
        const { data } = await POST_METHOD(`/analytics/properties`, body, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//update selected properties
export const updatePropertyById = async (body, id) => {
    try {
        const { data } = await PUT_METHOD(`/analytics/property/${id}`, body, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//update selected properties
export const getPropertyById = async (id) => {
    try {
        const { data } = await GET_METHOD(`/analytics/property/${id}`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};
