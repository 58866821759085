import React from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CreateChannel from "../../components/Popup/ChannelCreate";
import MainLayout from "../../layouts/MainDashboardComponent";
export default function ChannelCreate() {
  const location = useLocation();
  const navigate = useNavigate();

  const onClose = () => {
    const propertyId = location?.state?.propertyId
    navigate(propertyId ? -1 : "/dashboard")
  }

  return (
    <MainLayout>
      <div className="content_container_dashboard">
        <CreateChannel onClose={onClose} />
      </div>
    </MainLayout>
  );
}
