import { apiError } from "../../../functions/common";
import { getToken } from "../../../functions/localstorage";
import { GET_METHOD } from "../../axiosMethods";

const getHeaders = (token) => ({
    Authorization: token ?? getToken(),
});

//get my data
export const fetchDataSources = async () => {
    try {
        const { data } = await GET_METHOD(`/user/data-sources`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//get my data
export const fetchDataSourcesCounts = async () => {
    try {
        const { data } = await GET_METHOD(`/user/data-sources-counts`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//get my data
export const fetchAllUsersList = async () => {
    try {
        const { data } = await GET_METHOD(`/users/list`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//get my data
export const fetchUserTokenById = async (id) => {
    try {
        const { data } = await GET_METHOD(`/user/token/${id}`, { headers: getHeaders() });
        return data;
    } catch (error) {
        return apiError(error)
    }
};
