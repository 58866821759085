import React, { useEffect, useState } from "react";
import "./styles.scss";
import Navbar from "../../components/Navbar";
import Hero from "../../layouts/Hero";
import Steps from "../../layouts/Steps";
import Connect from "../../layouts/Connect";
import Contact from "../../layouts/Contact";
import { fetchMe } from "../../api/apis/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { addToken } from "../../functions/localstorage";
import { navigateAccToData } from "../../functions/common";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { updateLoginStatus } from "../../redux/reducers/appreducer";
import { toast } from "react-toastify";

export default function Home() {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const newIntegration = queryParams?.get('newIntegration') || false;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (window?.location?.href === "https://ahamoment.netlify.app/") {
      window?.location?.replace("https://ahamoment.co")
    }
    if (token) {
      validateAndSaveToken(token)
      addToken(token)
    }
  }, [])

  const validateAndSaveToken = async (token) => {
    setIsLoading(true)
    const response = await fetchMe(token)
    if (response?.success) {
      dispatch(updateLoginStatus(true))
      navigateAccToData(navigate)
    }
    if (newIntegration && newIntegration === 'true') {
      toast.success(
        "Aha Moment app is successfully installed in your workspace 👍",
        {
          toastId: "success1",
        }
      );
    }
  }



  return (
    <div>
      {isLoading && <Loader />}
      <Navbar pageName="home" />
      <Hero />
      <section id="how-it-works">
        <Steps />
      </section>
      <section id="connections">
        <Connect />
      </section>
      <section id="contact">
        <Contact page={"home"} />
      </section>
    </div>
  );
}
