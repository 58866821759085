import React, { useState } from "react";
import Connect from "../../Button/Connect";
import "./styles.scss";
import Loader from "../../Loader";
import Spinner from "../../Loader/Spinner";
export default function ConnectPopup({ items, title, isLoading }) {

  const [btnLoading, setBtnLoading] = useState(false);


  return (
    <div className="connect_popup_container">
      {isLoading ?
        <Loader />
        : ""}
      <div className="heading_container">
        <h3 className="heading">{title}</h3>
        <div className="line"></div>
      </div>
      {items?.map((item, index) => {
        return (
          <div key={index}>
            <div className="item_container">
              <div className="item_metadata_container">
                <div className="item_icon_container">
                  <img src={item.icon} className="item_icon" alt="" />
                </div>
                <div className="item_metadata_name_container">
                  <h4 className="item_name">{item.name}</h4>
                  <div className="connected_container">
                    <div
                      className={`${item.connected ? "green_circle" : "red_circle"
                        }`}
                    ></div>
                    <span className="connect_text">
                      {item?.connected ? "Connected" : "Not Connected"}
                    </span>
                  </div>
                </div>
              </div>
              {!item?.connected && <div className="connect_btn_container">
                <Connect
                  onClick={() => {
                    setBtnLoading(true)
                    item.onClick(setBtnLoading)
                  }
                  }
                  disabled={btnLoading}
                  spinner={btnLoading}
                  text={btnLoading ? <Spinner size={20} /> : "Connect"}
                />
              </div>}
            </div>
            <div className="item_line"></div>
          </div>
        );
      })}
    </div>
  );
}
