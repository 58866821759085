import { apiError } from "../../../functions/common";
import { getToken } from "../../../functions/localstorage";
import { GET_METHOD } from "../../axiosMethods";
import { API_URL } from "../../config";

const getHeaders = (token) => ({
    Authorization: token ?? getToken(),
});

//get my data
export const googleAuth = async () => {
    window.location.href = `${API_URL}auth/google`
    // window.location.replace()
};

//get my data
export const fetchMe = async (token) => {
    try {
        const { data } = await GET_METHOD("/user/me", { headers: getHeaders(token) });
        return data;
    } catch (error) {
        return apiError(error)
    }
};

//slack login
export const slackLogin = async (setBtnLoading, emptyStr) => {
    try {
        const { data } = await GET_METHOD("auth/slack/login", { headers: getHeaders() });
        window.location.href = data
        setBtnLoading && setBtnLoading(emptyStr ? "" : false);
    } catch (error) {
        return apiError(error)
    }
};

