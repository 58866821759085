import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connectAnalytics, fetchAnalytics } from "../../api/apis/analytics";
import { fetchDataSourcesCounts } from "../../api/apis/user";
import Continue from "../../components/Button/Continue";
import NavbarComponent from "../../components/Navbar";
import AnalyticsPopup from "../../components/Popup/Analytics";
import ConnectPopup from "../../components/Popup/Connect";
import NoAccountPopup from "../../components/Popup/NoAccount";
import { navigateAccToData } from "../../functions/common";

import GAnalytics from "../../assets/svg/GAnalytics.svg";
import "./styles.scss";
import MainLayout from "../../layouts/MainDashboardComponent";

export default function Connect() {

  const [analyticPopup, setAnalyticPopup] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [noAcc, setNoAcc] = useState(false);
  const [data, setData] = useState({
    analyticsData: [],
  });

  const queryParams = new URLSearchParams(location.search);
  const accId = queryParams.get("accId");
  const another = queryParams.get("another");
  useEffect(() => {
    setIsLoading(true)
    setConnected(false)
    accId ? checkAnalytics(accId) : fetchAllAccounts();
  }, []);

  const checkAnalytics = async (accId) => {
    if (accId !== "false") {
      await fetchAnalytics(accId);
    } else {
      setNoAcc(true);
    }
    fetchAllAccounts();
    navigate("/connect", { replace: true });
    another === "true" && setAnalyticPopup(true);
  };

  const fetchAllAccounts = async () => {
    const response = await fetchDataSourcesCounts();
    setData(response.data);
    setIsLoading(false);
  };

  const continuetoNext = async () => {
    setBtnLoading(true)
    navigateAccToData(navigate, true)
  };

  const items = [
    {
      icon: GAnalytics,
      name: "Google Analytics 4",
      connected: data?.analyticsIntegration > 0,
      onClick: () => connectAnalytics(),
    },
  ];


  return (
    <MainLayout>
      <div className="connect_main_container">
        {!analyticPopup && !noAcc && <NavbarComponent pageName="dashboard" />}
        {!analyticPopup && !noAcc && (
          <div className="content_container">
            <div>
              <ConnectPopup
                isLoading={isLoading}
                title={"Connect your data to the following:"}
                items={items}
              />
              <p className="content">
                These integrations are read-only; no changes will be made to your
                account.
              </p>
              {data?.analyticsIntegration > 0 && <div className="btn_container">
                <Continue
                  onClick={continuetoNext}
                  btnLoading={btnLoading}
                />
              </div>}
            </div>
          </div>
        )}

        <div className="main_popup_container">
          {analyticPopup && (
            <AnalyticsPopup
              onClose={() => setAnalyticPopup(false)}
              connected={connected}
            />
          )}
          {noAcc && <NoAccountPopup onClose={() => setNoAcc(false)} />}
        </div>
      </div>
    </MainLayout>
  );
}
