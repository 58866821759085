import React from "react";
import { CloseIcon } from "../../../assets/Icons";
import GAnalytics from "../../../assets/svg/GAnalytics.svg";
import NoAccount from "../../../assets/svg/no-account.svg";
import "./styles.scss";
export default function NoAccountPopup({ onClose }) {
  return (
    <div className="no_account_popup_container">
      <div className="heading_container">
        <div className="heading_logo_container justify-content-between">
          <div className="d-flex gap-2 align-items-center">
            <img src={GAnalytics} className="analytics_logo" alt="" />
            <h3 className="heading">No Accounts Retrieved</h3>
          </div>
          <div onClick={onClose} className="cursor__pointer">
            <CloseIcon size={18} />
          </div>
        </div>
        <div className="line"></div>
      </div>
      <div>
        <div class="img_container">
          <img src={NoAccount} class="no_account_img" alt="" />
        </div>
        <p class="message">
          There were 0 accounts retrieved for this login. Please log in again,
          or choose another account that may have access.
        </p>
      </div>
    </div>
  );
}
