import React, { useEffect, useState } from "react";
import Connect from "../../Button/Connect";
import RemoveButton from "../../Button/Remove";
import Loader from "../../Loader";
import { DeleteIcon } from "../../../assets/Icons";

import { connectAnalytics, fetchProperties, updateProperties } from "../../../api/apis/analytics";

import "./styles.scss";
import GAnalytics from "../../../assets/svg/GAnalytics.svg";

export default function DashboardPopup({
  handleAdd,
  handleSlack,
}) {

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false);



  const items = [
    // {
    //   icon: Slack,
    //   name: "Connect to Slack Channels",
    //   connected: true,
    //   properties: data?.slackChannels?.filter(ch => ch.isSelected)?.length ?? 0,
    // },
    {
      icon: GAnalytics,
      name: "Google Analytics 4",
      connected: data?.accounts?.length > 0,
      properties: data?.selectedProperties ?? [],
      type: "analytics"
    },
    // {
    //   icon: Adword,
    //   name: "Google Ad Words",
    //   connected: false,
    //   properties: 0,
    // },
  ];

  const fetchAllAccounts = async () => {
    setIsLoading(true)
    const response = await fetchProperties()
    setData(response.data)
    setIsLoading(false)
  }
  const removeProperty = async (property) => {
    setIsLoading(true);
    const properties = [];
    const deletedProperties = [{ ...property }];
    const newProperties = data?.selectedProperties?.filter((d) => d?.propertyId !== property?.propertyId)
    const newData = { ...data }
    newData.selectedProperties = newProperties
    console.log("Selected properties", newData, newProperties)
    setData(newData)
    await updateProperties({ properties, deletedProperties });
    // fetchAllAccounts();
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAllAccounts()
  }, [])

  return (
    <div className="dashboard_popup_container">
      {isLoading ?
        <Loader />
        : ""}
      <div className="heading_container">
        <h3 className="heading">Connections</h3>
        <h3 className="sub_title">
          These integrations are read-only; no changes will be made to your
          account.
        </h3>
        <div className="line"></div>
      </div>
      {items?.map((item, index) => {
        return (
          <div key={index}>
            <div className="item_container">
              <div className="item_metadata_container">
                <div className="item_icon_container">
                  <img src={item.icon} className="item_icon" alt="" />
                </div>
                <div className="item_metadata_name_container">
                  <h4 className="item_name">{item.name}</h4>
                  <div className="connected_container">
                    <div
                      className={`${item.connected ? "green_circle" : "red_circle"
                        }`}
                    ></div>
                    <span className="connect_text">
                      {item?.connected ? "Connected" : "Not Connected"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="connect_btn_container">
                <Connect
                  onClick={item?.connected ? handleAdd :connectAnalytics}
                  text={item?.connected
                    ? "Add Properties & Apps"
                    : "Connect"
                  }
                  type={"dashboard"}
                />
              </div>
            </div>
            {item?.connected && item?.properties?.map((property, index) => {
              console.log("Property", property)
              return <div className="properties_container" key={index}>
                <div className="d-flex gap-3">
                  <div>
                    <RemoveButton text={<DeleteIcon />} onClick={() => removeProperty(property)} />
                  </div>
                  <div className="properties_name_container">
                    <div className="properties_name">{property.name}</div>
                    <div className="properties_id">{property.propertyId}</div>
                    {property?.channelName && property?.channelName !== "" && <div className="properties_id">Connected Slack Channel: #{property?.channelName}</div>}
                  </div>
                </div>
                <div className="properties_remove_container">
                  <Connect
                    type={"dashboard"}
                    text={property?.channelName && property?.channelName !== "" ? "Edit Connected Slack Channel" : "Select Slack Channel"}
                    onClick={() => handleSlack(property?._id)} />
                </div>
              </div>
            })}
            <div className="item_line"></div>
          </div>
        );
      })}
    </div>
  );
}
