import React from "react";
import "./styles.scss";
export default function Connect({ text, onClick, type, spinner }) {
  return (
    <button
      className={`${
        text ==="Connect" || spinner ? "connect_btn" : "connect_btn_property"
      }`}
      onClick={() => onClick()}
    >
      {text}
    </button>
  );
}
