import React, { useEffect } from "react";
import NavbarComponent from "../../components/Navbar";
import DashboardPopup from "../../components/Popup/Dashboard";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainDashboardComponent"

export default function Dashboard() {

  const location = useLocation();
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate("/add-properties", { state: { dashboard: true } })
  };
  const handleSlack = (propertyId) => {
    navigate(`/select-channel/${propertyId}`, { state: { dashboard: true } })
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("another") === "true") {
      handleAdd()
      if (params.get("accId") === "false") {
      } else {
        navigate(location.pathname, { replace: true })
      }
    }
  }, [])

  return (
    <MainLayout>
      <NavbarComponent pageName={"dashboard"} />
      <div className="content_container_dashboard">
        <DashboardPopup
          handleAdd={() => handleAdd()}
          handleSlack={handleSlack}
        />
      </div>
    </MainLayout>
  );
}
