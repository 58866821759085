import React from 'react'
import NavbarComponent from '../../components/Navbar'
import Contact from '../../layouts/Contact'
import './styles.scss'
import { mail } from '../../utils/constants'

export default function PrivacyPolicy() {
    return (
        <div>
            <NavbarComponent pageName="home" />
            <div className='privacy_policy_container'>
                <div>
                    <h2 className='main_heading'>Terms and Conditions</h2>
                </div>
                <div className='content_container'>
                    <h3 className='content_heading'>1. Introduction</h3>
                    <p className='content'>Welcome to <a href='https://ahamoment.co/'>https://ahamoment.co/</a> ("Website," "Service," or "Platform"). These Terms and Conditions ("Terms") govern your use of our website. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our website.</p>

                    <h3 className='content_heading'>2. Use of the Website</h3>

                    <p className='content'>2.1 Eligibility <br />You must be at least 16 years old to use this website. By using this website, you represent and warrant that you are of legal age to form a binding contract with us.</p>

                    <p className='content'>2.2 Account Registration <br />To access certain features of our website, you may be required to register for an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and current.</p>

                    <p className='content'>2.3 User Responsibilities <br />You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You accept responsibility for all activities that occur under your account or password.</p>

                    <h3 className='content_heading'>3. Content</h3>

                    <p className='content'>3.1 Ownership <br />All content on this website, including text, graphics, logos, images, audio clips, digital downloads, data compilations, and software, is the property of [Website Name] or its content suppliers and protected by international copyright laws.</p>

                    <p className='content'>3.2 User-Generated Content <br />Users may contribute content to the website, such as comments, reviews, or forum posts. By submitting such content, you grant [Website Name] a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>

                    <h3 className='content_heading'>4. Privacy</h3>

                    <p className='content'>Our Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you agree to our Privacy Policy.</p>

                    <h3 className='content_heading'>5. Disclaimers</h3>

                    <p className='content'>5.1 No Warranty <br />This website is provided "as is" without any warranties, express or implied. [Website Name] makes no representations or warranties in relation to this website or the information and materials provided on this website.</p>

                    <p className='content'>5.2 Limitation of Liability <br />[Website Name] will not be liable to you (whether under the law of contact, the law of torts, or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website.</p>

                    <h3 className='content_heading'>6. Governing Law</h3>

                    <p className='content'>These Terms shall be governed by and construed in accordance with the laws of New York. Any disputes relating to these Terms will be subject to the exclusive jurisdiction of the courts in New York.</p>

                    <h3 className='content_heading'>7. Changes to Terms and Conditions</h3>

                    <p className='content'>We reserve the right to modify or replace these Terms at any time. Please review these Terms periodically. By continuing to use our website after any changes to these Terms, you agree to be bound by the revised Terms.</p>

                    <h3 className='content_heading'>8. Contact Us</h3>

                    <p className='content'>If you have any questions about these Terms and Conditions, please contact us at <a href={`mailto:${mail}`}>{mail}</a>.</p>

                    <p className='content'>Last Updated: 12/7/2023</p>
                </div>
            </div>
            <section id="contact">
                <Contact />
            </section>
        </div>
    )
}
