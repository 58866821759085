import React, { useEffect, useState } from "react";
import NavbarComponent from "../../components/Navbar";
import ConnectPopup from "../../components/Popup/Connect";
import SlackSvg from "../../assets/svg/slack.svg";
import "./styles.scss";
import { slackLogin } from "../../api/apis/auth";
import { fetchDataSourcesCounts } from "../../api/apis/user";
import Continue from "../../components/Button/Continue";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainDashboardComponent";
export default function ConnectSlack() {

  const navigate = useNavigate();

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)


  const items = [
    {
      icon: SlackSvg,
      name: "Slack",
      connected: data?.slackIntegration > 0,
      onClick: (setBtnLoading) => slackLogin(setBtnLoading),
    },
  ];

  const fetchAllAccounts = async () => {
    const response = await fetchDataSourcesCounts();
    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllAccounts();
  }, [])


  return (
    <MainLayout>
      <NavbarComponent pageName="dashboard" />
      <div className="content_container">
        <div>
          <ConnectPopup isLoading={isLoading} items={items} title={"Connect your Slack"} />
          {data?.slackIntegration ? <div className="btn_container">
            <Continue onClick={() => navigate("/dashboard")} />
          </div> : ""}
        </div>
      </div>
    </MainLayout>
  );
}
