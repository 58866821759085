import React from "react";
import "./styles.scss";
import emailIcon from "../../assets/svg/email.svg";
import { mail } from "../../utils/constants";
import Logo from "../../components/Logo";
export default function Contact(props) {

  return (
    <div className="contact_container_main">
      <div className="contact_container">
        {
          props?.page === 'home' && <div className="contact_title_container">
            <h4 className="title">Contact us</h4>
          </div>
        }
        {
          props?.page === 'home' && <div className="email_container">
            <img className="email_icon" src={emailIcon} alt="" />
            <h5 className="email_title">Email Address</h5>
            <a className="email" href={`mailto:${mail}`}>{mail}</a>
          </div>
        }
        <div className="footer_container">
          <div className="footer_bg">
            <div className="logo_container">
              <Logo imageClassName={"logo"} />
            </div>
            <div className="copy_right_container">
              <p className="content">
                Chat with your business data in Slack. Get quick, meaningful
                answers.
              </p>
            </div>
            <div className="quick_links_container">
              <ul className="quick_links">
                <li className="quick_links_item"><a className="quick_links_item" href={"/term-and-condition"}>Terms & Conditions</a></li>
                <li className="quick_links_item"><a className="quick_links_item" href={"/privacy-policy"}>Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
