import React from 'react';
import './styles.scss'
import { addToken, getCurrentToken, removeCurrentToken } from '../../functions/localstorage';
import { useSelector } from 'react-redux';

const MainLayout = ({ children }) => {

    const currentToken = getCurrentToken();

    const {userData} = useSelector(state => state);

    const removeImpersonate = () => {
        addToken(currentToken);
        removeCurrentToken();
        window.location.replace('/dashboard');
    }

    return (
        <div>
            {currentToken ? <div className='impersonate__container'>
                <button  onClick={removeImpersonate}>
                    You are impersonating {userData?.email} (Click here to go back to your account)
                </button>
            </div>:""}
            <div className={`main_container position-relative ${currentToken ? "is_impersonate":""}`}>
                {children}
            </div>
        </div>
    )
}

export default MainLayout;