import React from 'react';
import logo from "../../assets/svg/aha-logo.svg";
import "./styles.scss";

const Logo = ({imageClassName}) => {
    return (
        <div className="aha__moment__logo">
            <div>
                <img src={logo} className={`logo_img ${imageClassName ?? ""}`} alt="" />
            </div>
            {/* <div>
                Aha Moment
            </div> */}
        </div>
    )
}
export default Logo;