import React, { useState } from "react";
import SlackPopup from "../../components/Popup/Slack";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainDashboardComponent";
export default function Dashboard() {

  const [selected, setSelected] = useState([]);
  const [oldChannels, setOldChannels] = useState([])
  const [channels, setChannels] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className="content_container_dashboard">
        <SlackPopup
          onClose={() => {
            navigate(location?.state?.dashboard ? -1 : "/dashboard");
          }}
          channels={channels}
          setSelected={setSelected}
          selected={selected}
          setOldChannels={setOldChannels}
          oldChannels={oldChannels}
          setChannels={setChannels}
        />
      </div>
    </MainLayout>
  );
}
