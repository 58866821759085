import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";

//import functions
import { routes } from "./routes";
import { getToken, removeCurrentToken, removeToken } from "../functions/localstorage";
import { useDispatch } from "react-redux";
import { updateLoginStatus, updateUserData, updateLoading } from "../redux/reducers/appreducer";
import { fetchMe } from "../api/apis/auth";

export const Router = () => {

  const dispatch = useDispatch()
  const token = getToken();

  useEffect(() => {
    checkForLoggedIn()
  }, [])

  const checkForLoggedIn = async () => {
    if (token) {
      dispatch(updateLoading(true))
      dispatch(updateLoginStatus(true))
      const myData = await fetchMe();
      if (myData?.success) {
        dispatch(updateUserData(myData?.user))
      } else {
        removeCurrentToken()
        removeToken();
        dispatch(updateLoginStatus(false))
      }
      dispatch(updateLoading(false))
    }
  }


  const PrivateRoute = ({ children }) => {

    const navigate = useNavigate();
    const token = getToken();

    useEffect(() => {
      const fetchData = async () => {
        if (token) {
          try {
            const myData = await fetchMe();
            if (myData.success) {
              dispatch(updateUserData(myData?.user))
              if (window.location.pathname === "/super-admin") {
                if (!myData?.user?.isSuperAdmin) {
                  navigate("/dashboard", { replace: true })
                }
              }
            } else {
              removeCurrentToken()
              removeToken();
              navigate("/", { replace: true })
              dispatch(updateLoginStatus(false))
            }

          } catch (error) {
            console.error('Error fetching user data in PrivateRoute:', error);
          }
        }
      };

      fetchData();
    }, []);

    return token ? children : <Navigate to="/" replace />;
  };


  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, element, privateRoute }, index) => {
          return <Route key={index} path={path} element={privateRoute ? <PrivateRoute children={element} /> : element} />
        })}
      </Routes>
    </BrowserRouter>
  );
};
