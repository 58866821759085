export const CloseIcon = ({ color, size }) => {
    return <svg
        width={size ? size : "13"}
        height={size ? size : "13"}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z"
            fill={color ? color : "#fff"}
        />
    </svg>
}

export const DeleteIcon = ({ color, size }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4.06592H0V14.6731H11V4.06592ZM1.05811 13.6124V5.12671H3.24951V13.6124H1.05811ZM4.31023 13.6124H6.68701V5.12671H4.31023V13.6124ZM7.74773 13.6124V5.12671H9.93668V13.6124H7.74773Z" fill={color ?? "#D95934"} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96648 1.76843V1.06071H7.03076V1.76843H3.96648ZM2.90576 1.76843V0H8.09148V1.76843H11V2.82915H0V1.76843H2.90576Z" fill={color ?? "#D95934"} />
    </svg>
}